














































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import LsPagination from '@/components/ls-pagination.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
import ExportData from '@/components/export-data/index.vue'
import {
    apiDistributionGoodsLists,
    apiDistributionGoodsJoin
} from '@/api/distribution/distribution'
import { apiGoodsOtherList } from '@/api/goods'
@Component({
    components: {
        LsPagination,
        LsDialog,
        ExportData
    }
})
export default class DistributionGoods extends Vue {
    /** S Data **/
    apiDistributionGoodsLists = apiDistributionGoodsLists

    $refs!: { paneTable: any }

    goodsSearchData = {
        name: '',
        code: '',
        status: '',
        supplier_id: '',
        category_id: '',
        is_distribution: ''
    }

    categoryList = []

    options = [
        { value: '', label: '全部' },
        { value: '1', label: '参与分销' },
        { value: '0', label: '取消分销' }
    ]

    goodsStatus = [
        { value: '', label: '全部' },
        { value: '1', label: '上架' },
        { value: '0', label: '下架' },
        { value: '-1', label: '已售馨' }
    ]

    pager = new RequestPaging()

    selectIds: Array<string> = []

    /** E Data **/

    /** S Method **/

    // 获取商品分类
    getCategoryList() {
        apiGoodsOtherList({}).then((res: any) => {
            this.categoryList = res.category_list
        })
    }

    // 获取分销列表
    getDistributionData(page?: number): void {
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiDistributionGoodsLists,
            params: {
                ...this.goodsSearchData
            }
        })
    }

    // 选择某条数据
    selectionChange(val: any[]) {
        this.selectIds = val.map(item => item.id)
    }

    // 全选
    selectAll() {
        this.$refs.paneTable.toggleAllSelection()
    }

    //  参不参与分销
    couponDel(ids: Array<number>, flag: number): void {
        apiDistributionGoodsJoin({
            ids: Array.isArray(ids) ? ids : this.selectIds,
            is_distribution: flag
        }).then(res => {
            this.resetgoodsSearchData()
        })
    }

    // 重置
    resetgoodsSearchData() {
        Object.keys(this.goodsSearchData).map(key => {
            this.$set(this.goodsSearchData, key, '')
        })
        this.getDistributionData()
    }

    /** E Method **/

    created() {
        this.getDistributionData()
        this.getCategoryList()
    }
}
